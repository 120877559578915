<template>
  <v-app v-cloak>
    <SuccessNotifications />
    <ErrorNotifications />
    <template v-if="token && token.length">
      <AppToolbar />
      <AppNavigationDrawer />
    </template>
    <v-content
      :class="[
        'app-main',
        currentRouteName,
        {
          authorized: isAuthorized,
          drawer: isDrawerVisible,
          mini: !drawer,
        },
      ]"
    >
      <transition
        name="fade"
        mode="out-in"
      >
        <router-view></router-view>
      </transition>
    </v-content>
    <v-btn
      v-if="updateExists"
      round
      class="update-btn theme-primary-bg theme-on-primary text-none"
      @click="activateNewServiceWorker"
      >Update</v-btn
    >
    <Tawk />
  </v-app>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import Echo from "laravel-echo";
import SuccessNotifications from "./components/Notifications/SuccessNotifications";
import ErrorNotifications from "./components/Notifications/ErrorNotifications";
import Tawk from "@/components/Tawk";
import AppToolbar from "@/components/AppToolbar.vue";
import AppNavigationDrawer from "@/components/AppNavigationDrawer.vue";
import moment from "moment-timezone";
import update from "@/mixins/update";

export default {
  name: "App",
  mixins: [update],
  computed: {
    ...mapGetters(["getToken", "appLanguage", "userRolesInGroup"]),
    token() {
      return this.getToken;
    },
    isAuthorized() {
      return this.token ? true : false;
    },
    groupId() {
      return this.$route.params.group_id;
    },
    groupWatcher() {
      return [this.token, this.groupId];
    },
    drawer() {
      return this.$store.getters.getShowLeftMenu;
    },
    isDrawerVisible() {
      return this.$store.getters.isNavigationDrawerVisible;
    },
    currentRouteName() {
      return this.$route.name ? this.$route.name.toLowerCase() : "";
    },
  },
  components: {
    SuccessNotifications,
    ErrorNotifications,
    Tawk,
    AppToolbar,
    AppNavigationDrawer,
  },
  created() {
    this.removeDraggableGhost();
  },
  methods: {
    async getGlobalDateFormat() {
      try {
        const response = await this.$api.settings.get("global.date_format");

        if (
          response &&
          response.data &&
          response.data.data &&
          response.data.data.value
        ) {
          this.$store.dispatch("setGlobalDateFormat", response.data.data.value);
        }
      } catch (error) {
        if (error) {
          this.errorMessageShow(error);
        }
      }
    },
    async storeGroupData(groupId) {
      try {
        const params = [
          groupId,
          {
            with_attributes: 1,
          },
        ];

        const response = await this.$api.groups.get(...params);

        if (!response.data || !response.data.meta) {
          return;
        }

        this.$store.dispatch("setActiveGroup", response.data.data);
        this.$store.dispatch(
          "setUserRolesInGroup",
          response.data.meta.user_roles_in_group,
        );
      } catch (error) {
        this.errorMessageShow(error);
      }
    },
    clearSession() {
      this.$store.commit("SET_USER", {});
      this.$store.commit("SET_TOKEN", "");

      this.$router
        .push({
          name: "authorize",
        })
        .catch(() => {});
    },
    async setUser() {
      try {
        const authMeParams = { with_attributes: 1 };

        const authMeResponse = await this.$api.auth.me(authMeParams);

        if (!authMeResponse.data || !authMeResponse.data.data) {
          return null;
        }

        this.$store.commit("SET_USER", authMeResponse.data.data);

        const authPermissionsResponse = await this.$api.auth.permissions();

        this.$store.dispatch(
          "setCurrentUserPermissions",
          authPermissionsResponse.data.data,
        );
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.clearSession();
        }

        this.errorMessageShow(error);
      }
    },
    setGroupCount() {
      const params = { managed: 1, per_page: 1 };

      axios.get(`/groups`, params).then((response) => {
        if (
          response &&
          response.data &&
          response.data.pagination &&
          response.data.pagination.total
        ) {
          this.$store.dispatch(
            "setGroupCount",
            response.data.pagination.total || 0,
          );
        }
      });
    },
    initEcho(token) {
      if (process.env.VUE_APP_API_URL && process.env.VUE_APP_ECHO_PUSHER_KEY) {
        window.Echo = new Echo({
          broadcaster: "pusher",
          key: process.env.VUE_APP_ECHO_PUSHER_KEY,
          cluster: "eu",
          encrypted: true,
          auth: { headers: { Authorization: `Bearer ${token}` } },
          authEndpoint: process.env.VUE_APP_API_URL + "broadcasting/auth",
        });
      }
    },
    removeDraggableGhost() {
      document.addEventListener(
        "dragstart",
        function (e) {
          if (e.target.id === "vue-draggable") {
            const img = document.createElement("img");

            img.src =
              "data:image/gif;base64,R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=";
            e.dataTransfer.setDragImage(img, 0, 0);
          }
        },
        false,
      );
    },
  },
  watch: {
    token: {
      immediate: true,
      handler(val) {
        if (!val) return;

        axios.defaults.headers.common["Authorization"] = "Bearer " + val;

        this.initEcho(val);
        this.setGroupCount();
        this.setUser();
      },
    },
    appLanguage: {
      immediate: true,
      handler(val) {
        if (!val) return;

        this.$i18n.locale = val;

        moment.locale(val);
      },
    },
    groupWatcher: {
      immediate: true,
      handler(newVal, oldVal) {
        // if !user_token
        if (!newVal || !newVal[0]) return;

        // if !group_id
        if (!newVal[1]) {
          this.$store.dispatch("setActiveGroup", {});
          this.$store.dispatch("setUserRolesInGroup", []);
          return;
        }

        if (oldVal && oldVal[1] == newVal[1]) {
          return;
        }

        this.storeGroupData(newVal[1]);
        this.getGlobalDateFormat();
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.app-main {
  &.authorized {
    padding-top: 175px !important;

    &.drawer {
      padding-left: 320px !important;

      &.mini {
        padding-left: 180px !important;

        @media (max-width: 1500px) {
          padding-left: 80px !important;
        }
      }
    }
  }
}

.update-btn {
  position: fixed;
  left: 50%;
  bottom: 0;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 10;
}
</style>
