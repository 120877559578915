import axios from "axios";
import qs from "qs";

export const groupUsers = {
  list: (groupId, params) =>
    axios.get(`/groups/${groupId}/users`, {
      params: params,
      paramsSerializer: function (params) {
        return qs.stringify(params, {
          encode: false,
        });
      },
    }),
  get: (groupId, id, params) =>
    axios.get(`/groups/${groupId}/users/${id}`, { params }),
  subscribe: (groupId, id) =>
    axios.get(`/groups/${groupId}/users/${id}/subscribe`),
  unsubscribe: (groupId, id) =>
    axios.get(`/groups/${groupId}/users/${id}/unsubscribe`),
  app_users: (groupId, params) =>
    axios.get(`/groups/${groupId}/app_users`, { params }),
  delete: (groupId, id) => axios.delete(`/groups/${groupId}/users/${id}`),
  delete_all: (groupId, params) =>
    axios.delete(`/groups/${groupId}/users/delete_all`, { params }),
  complete_attribute_category: (groupId, id, params) =>
    axios.post(`groups/${groupId}/users/${id}/complete_attribute_category`, params),
};
