import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faQuestionCircle,
  faQuestion,
  faUsers,
  faUser,
  faUserCircle,
  faIdCardAlt,
  faIdCard,
  faAddressCard,
  faIdBadge,
  faBed,
  faCouch,
  faHotel,
  faHome,
  faNewspaper,
  faFileAlt,
  faInfo,
  faInfoCircle,
  faBarcode,
  faStickyNote,
  faEnvelopeOpenText,
  faEnvelopeOpen,
  faComments,
  faComment,
  faExclamation,
  faExclamationCircle,
  faExclamationTriangle,
  faTasks,
  faListAlt,
  faBusAlt,
  faCar,
  faTaxi,
  faClipboard,
  faClipboardList,
  faClipboardCheck,
  faClock,
  faDirections,
  faLocationArrow,
  faAtlas,
  faTicketAlt,
  faTshirt,
  faTag,
  faShoppingBag,
  faBriefcase,
  faCoffee,
  faAnchor,
  faAppleAlt,
  faAsterisk,
  faAtom,
  faBan,
  faBell,
  faCat,
  faCloud,
  faCode,
  faDog,
  faEye,
  faEyeSlash,
  faFileDownload,
  faFlask,
  faFutbol,
  faPalette,
  faPen,
  faSun,
  faTrash,
  faEnvelope,
  faSms,
  faAt,
  faGlassCheers,
  faUserTie,
  faAddressBook,
  faChartPie,
  faCog,
  faUserCog,
  faWrench,
  faImage,
  faPlus,
  faArrowsAlt,
  faBold,
  faItalic,
  faUnderline,
  faAlignRight,
  faAlignLeft,
  faAlignJustify,
  faAlignCenter,
  faEraser,
  faListUl,
  faListOl,
  faLink,
  faBookOpen,
  faMap,
  faFileCode,
  faFileCsv,
  faFileExcel,
  faFilePdf,
  faWineGlassAlt,
  faBullhorn,
  faBus,
  faPhoneSquare,
  faCheckCircle,
  faCheck,
  faRunning,
  faMapMarkerAlt,
  faPrint,
  faHammer,
  faPuzzlePiece,
  faBackspace,
  faGripVertical,
  faKeyboard,
  faMagic,
  faCodeBranch,
  faCopy,
  faMousePointer,
  faFileUpload,
  faCircle,
  faSearch,
  faKey,
  faLock,
  faUserShield,
  faShieldAlt,
  faGripLines,
  faGripLinesVertical,
  faClone,
  faRocket,
  faExternalLinkAlt,
  faTimes,
  faExpandAlt,
  faCompressAlt,
  faSquare,
  faCheckSquare,
  faMobile,
  faUsersCog,
  faCrop,
  faChartArea,
  faWallet,
} from "@fortawesome/free-solid-svg-icons";
import {
  faCircle as farCircle,
  faSquare as farSquare,
  faMinusSquare as farMinusSquare,
} from "@fortawesome/free-regular-svg-icons";
import { faPollPeople } from "@fortawesome/pro-regular-svg-icons";
import { faUserFriends as fadUserFriends } from "@fortawesome/pro-duotone-svg-icons";
library.add(
  faQuestionCircle,
  faQuestion,
  faUsers,
  faUser,
  faUserCircle,
  faIdCardAlt,
  faIdCard,
  faAddressCard,
  faIdBadge,
  faBed,
  faCouch,
  faHotel,
  faHome,
  faNewspaper,
  faFileAlt,
  faInfo,
  faInfoCircle,
  faBarcode,
  faStickyNote,
  faEnvelopeOpenText,
  faEnvelopeOpen,
  faComments,
  faComment,
  faExclamation,
  faExclamationCircle,
  faExclamationTriangle,
  faTasks,
  faListAlt,
  faBusAlt,
  faCar,
  faTaxi,
  faClipboard,
  faClipboardList,
  faClipboardCheck,
  faClock,
  faDirections,
  faLocationArrow,
  faAtlas,
  faTicketAlt,
  faTshirt,
  faTag,
  faShoppingBag,
  faBriefcase,
  faCoffee,
  faAnchor,
  faAppleAlt,
  faAsterisk,
  faAtom,
  faBan,
  faBell,
  faCat,
  faCloud,
  faCode,
  faDog,
  faEye,
  faEyeSlash,
  faFileDownload,
  faFlask,
  faFutbol,
  faPalette,
  faPen,
  faSun,
  faTrash,
  faEnvelope,
  faSms,
  faAt,
  faGlassCheers,
  faUserTie,
  faAddressBook,
  faChartPie,
  faCog,
  faUserCog,
  faWrench,
  faImage,
  faPlus,
  faArrowsAlt,
  faBold,
  faItalic,
  faUnderline,
  faAlignRight,
  faAlignLeft,
  faAlignJustify,
  faAlignCenter,
  faEraser,
  faListUl,
  faListOl,
  faLink,
  faBookOpen,
  faMap,
  faFileCode,
  faFileCsv,
  faFileExcel,
  faFilePdf,
  faWineGlassAlt,
  faBullhorn,
  faBus,
  faPhoneSquare,
  faCheckCircle,
  faCheck,
  faRunning,
  faMapMarkerAlt,
  faPrint,
  faHammer,
  faPuzzlePiece,
  faBackspace,
  faGripVertical,
  faKeyboard,
  faMagic,
  faCodeBranch,
  faCopy,
  faMousePointer,
  faFileUpload,
  faCircle,
  farCircle,
  faSearch,
  faKey,
  faLock,
  faUserShield,
  faShieldAlt,
  faGripLines,
  faGripLinesVertical,
  faClone,
  faRocket,
  faExternalLinkAlt,
  faTimes,
  faExpandAlt,
  faCompressAlt,
  faSquare,
  farSquare,
  farMinusSquare,
  faCheckSquare,
  faMobile,
  fadUserFriends,
  faPollPeople,
  faUsersCog,
  faCrop,
  faChartArea,
  faWallet,
);
