import Vue from "vue";
import Router from "vue-router";
import store from "@/store/core";

Vue.use(Router);

const router = new Router({
  saveScrollPosition: false,
  history: true,
  routes: [
    {
      path: "/",
      name: "home",
      component: () => import("@/views/GroupListPage.vue"),
      meta: {
        permissions: [],
        groupPolicies: [],
      },
    },
    {
      path: "/register",
      name: "register",
      component: () => import("@/views/Register.vue"),
    },
    {
      path: "/login",
      name: "authorize",
      component: () => import("@/views/Authorize.vue"),
    },
    {
      path: "/profile",
      name: "profile",
      component: () => import("@/views/ProfilePage.vue"),
      meta: {
        permissions: [],
        groupPolicies: [],
      },
    },
    {
      path: "/status",
      name: "status",
      component: () => import("@/views/AppStatusPage.vue"),
      meta: {
        permissions: [
          "settings_list",
          "group_plugin_general_attribute_list",
          "group_article_general_attribute_list",
          "user_general_attribute_list",
          "group_role_list",
        ],
        groupPolicies: [],
      },
    },
    {
      path: "/migrations",
      name: "migrations",
      component: () => import("@/views/MigrationsPage.vue"),
      meta: {
        permissions: ["admin_permissions"],
        groupPolicies: [],
      },
    },
    {
      path: "/settings",
      name: "settings",
      component: () => import("@/views/SettingsListPage.vue"),
      meta: {
        permissions: ["settings_list"],
        groupPolicies: [],
      },
    },
    {
      path: "/settings/create",
      name: "settings_create",
      component: () => import("@/views/SettingsPage.vue"),
      meta: {
        permissions: [],
        groupPolicies: [],
      },
    },
    {
      path: "/settings/:setting_id/edit",
      name: "settings_edit",
      component: () => import("@/views/SettingsPage.vue"),
      meta: {
        permissions: [],
        groupPolicies: [],
      },
    },
    {
      path: "/users",
      name: "users",
      component: () => import("@/views/UserListPage.vue"),
      meta: {
        permissions: [
          "admin_user_list",
          "admin_user_create",
          "admin_user_show",
          "admin_user_update",
          "admin_user_delete",
          "admin_role_list",
        ],
        groupPolicies: [],
      },
    },
    {
      path: "/users/create",
      name: "users_create",
      component: () => import("@/views/UserPage.vue"),
      meta: {
        permissions: [],
        groupPolicies: [],
      },
    },
    {
      path: "/users/:user_id/edit",
      name: "users_edit",
      component: () => import("@/views/UserPage.vue"),
      meta: {
        permissions: [],
        groupPolicies: [],
      },
    },
    {
      path: "/roles",
      name: "roles",
      component: () => import("@/views/RoleListPage.vue"),
      meta: {
        permissions: ["admin_role_update", "admin_role_delete"],
        groupPolicies: [],
      },
    },
    {
      path: "/roles/create",
      name: "roles_create",
      component: () => import("@/views/RolePage.vue"),
      meta: {
        permissions: [],
        groupPolicies: [],
      },
    },
    {
      path: "/roles/:role_id/edit",
      name: "roles_edit",
      component: () => import("@/views/RolePage.vue"),
      meta: {
        permissions: [],
        groupPolicies: [],
      },
    },
    {
      path: "/group-roles",
      name: "group_roles",
      component: () => import("@/views/GroupRoleListPage.vue"),
      meta: {
        permissions: [
          "group_role_list",
          "group_role_store",
          "group_role_show",
          "group_role_destroy",
        ],
        groupPolicies: [],
      },
    },
    {
      path: "/group-roles/create",
      name: "group_roles_create",
      component: () => import("@/views/GroupRolePage.vue"),
      meta: {
        permissions: [],
        groupPolicies: [],
      },
    },
    {
      path: "/group-roles/:role_id/edit",
      name: "group_roles_edit",
      component: () => import("@/views/GroupRolePage.vue"),
      meta: {
        permissions: [],
        groupPolicies: [],
      },
    },
    {
      path: "/group-policies",
      name: "group_policies",
      component: () => import("@/views/GroupPolicyListPage.vue"),
      meta: {
        permissions: ["group_policy_show"],
        groupPolicies: [],
      },
    },
    {
      path: "/group-policies/:policy_id/edit",
      name: "group_policies_edit",
      component: () => import("@/views/GroupPolicyPage.vue"),
      meta: {
        permissions: [],
        groupPolicies: [],
      },
    },
    {
      path: "/printout-templates",
      name: "printout_templates",
      component: () => import("@/views/PrintoutTemplateListPage.vue"),
      meta: {
        permissions: [
          "printout_list",
          "printout_show",
          "printout_create",
          "printout_update",
          "printout_delete",
        ],
        groupPolicies: [],
      },
    },
    {
      path: "/printout-templates/create",
      name: "printout_templates_create",
      component: () => import("@/views/PrintoutTemplatePage.vue"),
      meta: {
        permissions: [],
        groupPolicies: [],
      },
    },
    {
      path: "/printout-templates/:printout_template_id/edit",
      name: "printout_templates_edit",
      component: () => import("@/views/PrintoutTemplatePage.vue"),
      meta: {
        permissions: [],
        groupPolicies: [],
      },
    },
    {
      path: "/messages",
      name: "message_templates",
      component: () => import("@/views/MessageTemplateListPage.vue"),
      meta: {
        permissions: [
          "message_templates_list",
          "message_templates_store",
          "message_templates_show",
          "message_templates_update",
          "message_templates_destroy",
        ],
        groupPolicies: [],
      },
    },
    {
      path: "/messages/create",
      name: "message_templates_create",
      component: () => import("@/views/MessageTemplatePage.vue"),
      meta: {
        permissions: [],
        groupPolicies: [],
      },
    },
    {
      path: "/messages/:message_id/edit",
      name: "message_templates_edit",
      component: () => import("@/views/MessageTemplatePage.vue"),
      meta: {
        permissions: [],
        groupPolicies: [],
      },
    },
    {
      path: "/venues",
      name: "venues",
      component: () => import("@/views/VenueListPage.vue"),
      meta: {
        permissions: [
          "group_venue_create",
          "group_venue_update",
          "group_venue_delete",
        ],
        groupPolicies: [],
      },
    },
    {
      path: "/venues/create",
      name: "venues_create",
      component: () => import("@/views/VenuePage.vue"),
      meta: {
        permissions: [],
        groupPolicies: [],
      },
    },
    {
      path: "/venues/:venue_id/edit",
      name: "venues_edit",
      component: () => import("@/views/VenuePage.vue"),
      meta: {
        permissions: [],
        groupPolicies: [],
      },
    },
    {
      path: "/groups/:group_id/get-started",
      component: () => import("@/views/GetStarted.vue"),
      name: "get_started",
      meta: {
        permissions: [],
        groupPolicies: [
          "groupArticleStore",
          "groupTicketTemplatesStore",
          "groupMessageTemplatesStore",
        ],
      },
    },
    {
      path: "/groups/:group_id/dashboard",
      component: () => import("@/views/Dashboard.vue"),
      name: "dashboard",
      meta: {
        permissions: [],
        groupPolicies: [
          "groupUserIndex",
          "groupUserAppUsers",
          "groupMessageTemplatesIndex",
        ],
      },
    },
    {
      path: "/groups/:group_id/transactions",
      name: "group_transactions",
      component: () => import("@/views/GroupTransactions.vue"),
      meta: {
        permissions: [],
        groupPolicies: [],
      },
    },
    {
      path: "/groups/:group_id/triggers",
      name: "triggers",
      component: () => import("@/views/TriggerListPage.vue"),
      meta: {
        permissions: [],
        groupPolicies: [],
      },
    },
    {
      path: "/groups/:group_id/triggers/create",
      name: "triggers_create",
      component: () => import("@/views/TriggerPage.vue"),
      meta: {
        permissions: [],
        groupPolicies: [],
      },
    },
    {
      path: "/groups/:group_id/triggers/:trigger_id/edit",
      name: "triggers_edit",
      component: () => import("@/views/TriggerPage.vue"),
      meta: {
        permissions: [],
        groupPolicies: [],
      },
    },
    {
      path: "/groups",
      name: "groups",
      component: () => import("@/views/GroupListPage.vue"),
      meta: {
        permissions: [],
        groupPolicies: [],
      },
    },
    {
      path: "/groups/create-wizard",
      name: "groups_create_wizard",
      component: () => import("@/views/GroupWizard.vue"),
      meta: {
        permissions: [],
        groupPolicies: ["update", "delete"],
      },
    },
    {
      path: "/groups/:group_id/edit-wizard",
      name: "groups_edit_wizard",
      component: () => import("@/views/GroupWizard.vue"),
      meta: {
        permissions: [],
        groupPolicies: ["update", "delete"],
      },
    },
    {
      path: "/groups/:group_id/edit",
      name: "groups_edit",
      component: () => import("@/views/GroupPage.vue"),
      meta: {
        permissions: [],
        groupPolicies: ["update", "delete"],
      },
    },
    // {
    //   path: "/groups/:group_id/plugins",
    //   name: "group_plugins",
    //   component: () => import("@/views/GroupPluginListPage.vue"),
    //   meta: {
    //     permissions: [],
    //     groupPolicies: [],
    //   },
    // },
    // {
    //   path: "/groups/:group_id/plugins/create",
    //   name: "group_plugins_create",
    //   component: () => import("@/views/GroupPluginPage.vue"),
    //   meta: {
    //     permissions: [],
    //     groupPolicies: [],
    //   },
    // },
    // {
    //   path: "/groups/:group_id/plugins/:plugin_id/edit",
    //   name: "group_plugins_edit",
    //   component: () => import("@/views/GroupPluginPage.vue"),
    //   meta: {
    //     permissions: [],
    //     groupPolicies: [],
    //   },
    // },
    {
      path: "/groups/:group_id/ticket-templates",
      name: "ticket_templates",
      component: () => import("@/views/TicketTemplateListPage.vue"),
      meta: {
        permissions: [],
        groupPolicies: [
          "groupTicketTemplatesIndex",
          "groupTicketTemplatesShow",
          "groupTicketTemplatesStore",
          "groupTicketTemplatesUpdate",
          "groupTicketTemplatesDestroy",
          "groupTicketTemplatesPrintNametag",
        ],
      },
    },
    {
      path: "/groups/:group_id/ticket-templates/create",
      name: "ticket_templates_create",
      component: () => import("@/views/TicketTemplateWizard.vue"),
      meta: {
        permissions: [],
        groupPolicies: [
          "groupTicketTemplatesIndex",
          "groupTicketTemplatesShow",
          "groupTicketTemplatesStore",
          "groupTicketTemplatesUpdate",
          "groupTicketTemplatesDestroy",
          "groupTicketTemplatesPrintNametag",
        ],
      },
    },
    {
      path: "/groups/:group_id/ticket-templates/:group_plugin_id/edit",
      name: "ticket_templates_edit",
      component: () => import("@/views/TicketTemplateWizard.vue"),
      meta: {
        permissions: [],
        groupPolicies: [
          "groupTicketTemplatesIndex",
          "groupTicketTemplatesShow",
          "groupTicketTemplatesStore",
          "groupTicketTemplatesUpdate",
          "groupTicketTemplatesDestroy",
          "groupTicketTemplatesPrintNametag",
        ],
      },
    },
    {
      path: "/groups/:group_id/tickets/:group_plugin_id/:ticket_template_id",
      name: "tickets",
      component: () => import("@/views/TicketListPage.vue"),
      meta: {
        permissions: [],
        groupPolicies: [
          "groupTicketsIndex",
          "groupTicketsShow",
          "groupTicketsStore",
          "groupTicketsCheck",
          "groupTicketsCheckin",
          "groupTicketsCheckout",
          "groupTicketsDownload",
          "groupTicketsInvalidate",
          "groupTicketsDeletePdf",
        ],
      },
    },
    {
      path: "/groups/:group_id/tickets/:group_plugin_id/:ticket_template_id/:ticket_id/show",
      name: "tickets_show",
      component: () => import("@/views/TicketPage.vue"),
      meta: {
        permissions: [],
        groupPolicies: [
          "groupTicketsIndex",
          "groupTicketsShow",
          "groupTicketsStore",
          "groupTicketsCheck",
          "groupTicketsCheckin",
          "groupTicketsCheckout",
          "groupTicketsDownload",
          "groupTicketsInvalidate",
          "groupTicketsDeletePdf",
        ],
      },
    },
    {
      path: "/groups/:group_id/checkin/:group_plugin_id/:ticket_template_id",
      name: "checkin",
      component: () => import("@/views/checkin/Checkin.vue"),
      meta: {
        permissions: [],
        groupPolicies: [
          "groupTicketsIndex",
          "groupTicketsShow",
          "groupTicketsStore",
          "groupTicketsCheck",
          "groupTicketsCheckin",
          "groupTicketsCheckout",
          "groupTicketsDownload",
          "groupTicketsInvalidate",
          "groupTicketsDeletePdf",
        ],
      },
    },
    {
      path: "/groups/:group_id/deposit-settings/:group_plugin_id/create",
      name: "deposit_settings_create",
      component: () =>
        import("@/views/deposit_settings/DepositSettingsForm.vue"),
      meta: {
        permissions: [],
        groupPolicies: [
          "groupDepositoryIndex",
          "groupDepositoryShow",
          "groupDepositoryStore",
          "groupDepositoryUpdate",
          "groupDepositoryDestroy",
          "groupDepositIndex",
          "groupDepositStore",
          "groupDepositUpdate",
          "groupDepositDestroy",
          "groupDepositHistory",
          "groupDepositCount",
          "groupDepositTypeShow",
          "groupDepositTypeStore",
          "groupDepositTypeUpdate",
          "groupDepositTypeDestroy",
        ],
      },
    },
    {
      path: "/groups/:group_id/deposit-settings/:group_plugin_id",
      name: "deposit_settings",
      component: () => import("@/views/deposit_settings/DepositSettings.vue"),
      meta: {
        permissions: [],
        groupPolicies: [
          "groupDepositoryIndex",
          "groupDepositoryShow",
          "groupDepositoryStore",
          "groupDepositoryUpdate",
          "groupDepositoryDestroy",
          "groupDepositIndex",
          "groupDepositStore",
          "groupDepositUpdate",
          "groupDepositDestroy",
          "groupDepositHistory",
          "groupDepositCount",
          "groupDepositTypeShow",
          "groupDepositTypeStore",
          "groupDepositTypeUpdate",
          "groupDepositTypeDestroy",
        ],
      },
    },
    {
      path: "/groups/:group_id/deposit-settings/:group_plugin_id/:deposit_type_id",
      name: "deposit_settings_edit",
      component: () =>
        import("@/views/deposit_settings/DepositSettingsForm.vue"),
      meta: {
        permissions: [],
        groupPolicies: [
          "groupDepositoryIndex",
          "groupDepositoryShow",
          "groupDepositoryStore",
          "groupDepositoryUpdate",
          "groupDepositoryDestroy",
          "groupDepositIndex",
          "groupDepositStore",
          "groupDepositUpdate",
          "groupDepositDestroy",
          "groupDepositHistory",
          "groupDepositCount",
          "groupDepositTypeShow",
          "groupDepositTypeStore",
          "groupDepositTypeUpdate",
          "groupDepositTypeDestroy",
        ],
      },
    },
    {
      path: "/groups/:group_id/deposits/:group_plugin_id",
      name: "deposits",
      component: () => import("@/views/deposits/Deposits.vue"),
      meta: {
        permissions: [],
        groupPolicies: [
          "groupDepositoryIndex",
          "groupDepositoryShow",
          "groupDepositoryStore",
          "groupDepositoryUpdate",
          "groupDepositoryDestroy",
          "groupDepositIndex",
          "groupDepositStore",
          "groupDepositUpdate",
          "groupDepositDestroy",
          "groupDepositHistory",
          "groupDepositCount",
          "groupDepositTypeShow",
          "groupDepositTypeStore",
          "groupDepositTypeUpdate",
          "groupDepositTypeDestroy",
        ],
      },
    },
    {
      path: "/groups/:group_id/tickets-stats/:group_plugin_id/:ticket_template_id",
      name: "ticket_stats",
      component: () => import("@/views/TicketStatsPage.vue"),
      meta: {
        permissions: [],
        groupPolicies: ["groupUserIndex", "groupUserAppUsers"],
      },
    },
    {
      path: "/groups/:group_id/health",
      name: "groups_health",
      component: () => import("@/views/GroupHealthPage.vue"),
      meta: {
        permissions: [],
        groupPolicies: [],
      },
    },
    {
      path: "/groups/:group_id/menus/create",
      name: "group_menu_create",
      component: () => import("@/views/GroupMenu.vue"),
      meta: {
        permissions: [],
        groupPolicies: [
          "groupMenuDestinationsStore",
          "groupMenuDestinationsUpdate",
          "groupMenuDestinationsDestroy",
          "groupMenuDestinationsReorder",
          "groupMenusStore",
          "groupMenusUpdate",
        ],
      },
    },
    {
      path: "/groups/:group_id/menus/:menu_id/edit",
      name: "group_menu_edit",
      component: () => import("@/views/GroupMenu.vue"),
      meta: {
        permissions: [],
        groupPolicies: [
          "groupMenuDestinationsStore",
          "groupMenuDestinationsUpdate",
          "groupMenuDestinationsDestroy",
          "groupMenuDestinationsReorder",
          "groupMenusStore",
          "groupMenusUpdate",
        ],
      },
    },
    {
      path: "/groups/:group_id/menus",
      name: "group_menu_list",
      component: () => import("@/views/GroupMenuList.vue"),
      meta: {
        permissions: [],
        groupPolicies: [
          "groupMenuDestinationsStore",
          "groupMenuDestinationsUpdate",
          "groupMenuDestinationsDestroy",
          "groupMenuDestinationsReorder",
          "groupMenusStore",
          "groupMenusUpdate",
        ],
      },
    },
    {
      path: "/groups/:group_id/menus/:menu_id/destination/create",
      name: "group_menu_destination_create",
      component: () => import("@/views/GroupMenuDestination.vue"),
      meta: {
        permissions: [],
        groupPolicies: [
          "groupMenuDestinationsStore",
          "groupMenuDestinationsUpdate",
          "groupMenuDestinationsDestroy",
          "groupMenuDestinationsReorder",
          "groupMenusStore",
          "groupMenusUpdate",
        ],
      },
    },
    {
      path: "/groups/:group_id/menus/:menu_id/destination/:destination_id/edit",
      name: "group_menu_destination_edit",
      component: () => import("@/views/GroupMenuDestination.vue"),
      meta: {
        permissions: [],
        groupPolicies: [
          "groupMenuDestinationsStore",
          "groupMenuDestinationsUpdate",
          "groupMenuDestinationsDestroy",
          "groupMenuDestinationsReorder",
          "groupMenusStore",
          "groupMenusUpdate",
        ],
      },
    },
    {
      path: "/groups/:group_id/menus/:menu_id/destinations",
      name: "group_menu_destination_list",
      component: () => import("@/views/GroupMenuDestinationList.vue"),
      meta: {
        permissions: [],
        groupPolicies: [
          "groupMenuDestinationsStore",
          "groupMenuDestinationsUpdate",
          "groupMenuDestinationsDestroy",
          "groupMenuDestinationsReorder",
          "groupMenusStore",
          "groupMenusUpdate",
        ],
      },
    },
    {
      path: "/groups/:group_id/audiences",
      name: "audiences",
      component: () => import("@/views/AudienceListPage.vue"),
      meta: {
        permissions: [],
        groupPolicies: ["groupAudiencesIndex", "groupAudiencesShow"],
      },
    },
    {
      path: "/groups/:group_id/audiences/create",
      name: "audiences_create",
      component: () => import("@/views/AudiencePage.vue"),
      meta: {
        permissions: [],
        groupPolicies: [
          "groupAudiencesIndex",
          "groupAudiencesShow",
          "groupAudiencesStore",
          "groupAudiencesUpdate",
          "groupAudiencesDestroy",
        ],
      },
    },
    {
      path: "/groups/:group_id/audiences/:audience_id/edit",
      name: "audiences_edit",
      component: () => import("@/views/AudiencePage.vue"),
      meta: {
        permissions: [],
        groupPolicies: [
          "groupAudiencesIndex",
          "groupAudiencesShow",
          "groupAudiencesStore",
          "groupAudiencesUpdate",
          "groupAudiencesDestroy",
        ],
      },
    },
    {
      path: "/groups/:group_id/members",
      name: "group_users",
      component: () => import("@/views/GroupUserListPage.vue"),
      meta: {
        permissions: [],
        groupPolicies: [
          "groupUserIndex",
          "groupUserShow",
          "groupUserSubscribe",
          "groupUserUnsubscribe",
          "groupUserDownloadSample",
          "groupUserImport",
        ],
      },
    },
    {
      path: "/groups/:group_id/members/:user_id/edit",
      name: "group_users_edit",
      component: () => import("@/views/GroupUserPage.vue"),
      meta: {
        permissions: [],
        groupPolicies: [
          "groupUserIndex",
          "groupUserShow",
          "groupUserSubscribe",
          "groupUserUnsubscribe",
          "groupUserAppUsers",
          "groupUserDownloadSample",
          "groupUserImport",
        ],
      },
    },
    {
      path: "/groups/:group_id/members/attribute-import",
      name: "group_user_attribute_import",
      component: () => import("@/views/GroupUserAttributeImport.vue"),
      meta: {
        permissions: [],
        groupPolicies: ["groupUserDownloadSample", "groupUserImport"],
      },
    },
    {
      path: "/groups/:group_id/guest-attributes/create",
      name: "group_user_attributes_create",
      component: () => import("@/views/GroupUserAttributeWizard.vue"),
      meta: {
        permissions: [],
        groupPolicies: ["groupUserIndex", "groupUserShow"],
      },
    },
    {
      path: "/groups/:group_id/guest-attributes",
      name: "group_user_attributes",
      component: () => import("@/views/GroupUserAttributeListPage.vue"),
      meta: {
        permissions: [],
        groupPolicies: ["groupUserIndex", "groupUserShow"],
      },
    },
    {
      path: "/groups/:group_id/guest-attributes/:group_plugin_id/edit",
      name: "group_user_attributes_edit",
      component: () => import("@/views/GroupUserAttributeWizard.vue"),
      meta: {
        permissions: [],
        groupPolicies: ["groupUserIndex", "groupUserShow"],
      },
    },
    {
      path: "/groups/:group_id/signups",
      name: "signups",
      component: () => import("@/views/SignupListPage.vue"),
      meta: {
        permissions: [],
        groupPolicies: [
          "groupPluginsStore",
          "groupPluginsUpdate",
          "groupPluginsDestroy",
          "groupMessageTemplatesIndex",
          "groupMessageTemplatesShow",
          "groupMessageTemplatesStore",
          "groupMessageTemplatesUpdate",
          "groupMessageTemplatesDestroy",
          "groupMessageTemplatesSendTest",
          "groupUserAttributeCategoryAttributesIndex",
          "groupUserAttributeCategoryAttributesShow",
          "groupUserAttributeCategoryAttributesStore",
          "groupUserAttributeCategoryAttributesUpdate",
          "groupUserAttributeCategoryAttributesDestroy",
          "groupUserAttributeCategoryIndex",
          "groupUserAttributeCategoryShow",
          "groupUserAttributeCategoryStore",
          "groupUserAttributeCategoryUpdate",
          "groupUserAttributeCategoryDestroy",
          "groupUserAttributeCategoryShowPublic",
          "groupUserAttributeStore",
          "groupUserAttributeSort",
          "groupUserAttributeUpdate",
          "groupUserAttributeDestroy",
          "groupUserAttributeDownloadSample",
          "groupUserAttributeImport",
          "groupUserAttributeRelationShow",
          "groupUserAttributeRelationStore",
          "groupUserAttributeRelationDestroy",
        ],
      },
    },
    {
      path: "/groups/:group_id/signups/create",
      name: "signups_create",
      component: () => import("@/views/SignupWizard.vue"),
      meta: {
        permissions: [],
        groupPolicies: [
          "groupPluginsStore",
          "groupPluginsUpdate",
          "groupPluginsDestroy",
          "groupMessageTemplatesIndex",
          "groupMessageTemplatesShow",
          "groupMessageTemplatesStore",
          "groupMessageTemplatesUpdate",
          "groupMessageTemplatesDestroy",
          "groupMessageTemplatesSendTest",
          "groupUserAttributeCategoryAttributesIndex",
          "groupUserAttributeCategoryAttributesShow",
          "groupUserAttributeCategoryAttributesStore",
          "groupUserAttributeCategoryAttributesUpdate",
          "groupUserAttributeCategoryAttributesDestroy",
          "groupUserAttributeCategoryIndex",
          "groupUserAttributeCategoryShow",
          "groupUserAttributeCategoryStore",
          "groupUserAttributeCategoryUpdate",
          "groupUserAttributeCategoryDestroy",
          "groupUserAttributeCategoryShowPublic",
          "groupUserAttributeStore",
          "groupUserAttributeSort",
          "groupUserAttributeUpdate",
          "groupUserAttributeDestroy",
          "groupUserAttributeDownloadSample",
          "groupUserAttributeImport",
          "groupUserAttributeRelationShow",
          "groupUserAttributeRelationStore",
          "groupUserAttributeRelationDestroy",
        ],
      },
    },
    {
      path: "/groups/:group_id/signups/:group_plugin_id/edit",
      name: "signups_edit",
      component: () => import("@/views/SignupWizard.vue"),
      meta: {
        permissions: [],
        groupPolicies: [
          "groupPluginsStore",
          "groupPluginsUpdate",
          "groupPluginsDestroy",
          "groupMessageTemplatesIndex",
          "groupMessageTemplatesShow",
          "groupMessageTemplatesStore",
          "groupMessageTemplatesUpdate",
          "groupMessageTemplatesDestroy",
          "groupMessageTemplatesSendTest",
          "groupUserAttributeCategoryAttributesIndex",
          "groupUserAttributeCategoryAttributesShow",
          "groupUserAttributeCategoryAttributesStore",
          "groupUserAttributeCategoryAttributesUpdate",
          "groupUserAttributeCategoryAttributesDestroy",
          "groupUserAttributeCategoryIndex",
          "groupUserAttributeCategoryShow",
          "groupUserAttributeCategoryStore",
          "groupUserAttributeCategoryUpdate",
          "groupUserAttributeCategoryDestroy",
          "groupUserAttributeCategoryShowPublic",
          "groupUserAttributeStore",
          "groupUserAttributeSort",
          "groupUserAttributeUpdate",
          "groupUserAttributeDestroy",
          "groupUserAttributeDownloadSample",
          "groupUserAttributeImport",
          "groupUserAttributeRelationShow",
          "groupUserAttributeRelationStore",
          "groupUserAttributeRelationDestroy",
        ],
      },
    },
    {
      path: "/groups/:group_id/questions",
      name: "questions",
      component: () => import("@/views/QuestionListPage.vue"),
      meta: {
        permissions: [],
        groupPolicies: [
          "groupPluginsStore",
          "groupPluginsUpdate",
          "groupPluginsDestroy",
          "groupMessageTemplatesIndex",
          "groupMessageTemplatesShow",
          "groupMessageTemplatesStore",
          "groupMessageTemplatesUpdate",
          "groupMessageTemplatesDestroy",
          "groupMessageTemplatesSendTest",
          "groupUserAttributeCategoryAttributesIndex",
          "groupUserAttributeCategoryAttributesShow",
          "groupUserAttributeCategoryAttributesStore",
          "groupUserAttributeCategoryAttributesUpdate",
          "groupUserAttributeCategoryAttributesDestroy",
          "groupUserAttributeCategoryIndex",
          "groupUserAttributeCategoryShow",
          "groupUserAttributeCategoryStore",
          "groupUserAttributeCategoryUpdate",
          "groupUserAttributeCategoryDestroy",
          "groupUserAttributeCategoryShowPublic",
          "groupUserAttributeStore",
          "groupUserAttributeSort",
          "groupUserAttributeUpdate",
          "groupUserAttributeDestroy",
          "groupUserAttributeDownloadSample",
          "groupUserAttributeImport",
          "groupUserAttributeRelationShow",
          "groupUserAttributeRelationStore",
          "groupUserAttributeRelationDestroy",
        ],
      },
    },
    {
      path: "/groups/:group_id/questions/create",
      name: "questions_create",
      component: () => import("@/views/QuestionWizard.vue"),
      meta: {
        permissions: [],
        groupPolicies: [
          "groupPluginsStore",
          "groupPluginsUpdate",
          "groupPluginsDestroy",
          "groupMessageTemplatesIndex",
          "groupMessageTemplatesShow",
          "groupMessageTemplatesStore",
          "groupMessageTemplatesUpdate",
          "groupMessageTemplatesDestroy",
          "groupMessageTemplatesSendTest",
          "groupUserAttributeCategoryAttributesIndex",
          "groupUserAttributeCategoryAttributesShow",
          "groupUserAttributeCategoryAttributesStore",
          "groupUserAttributeCategoryAttributesUpdate",
          "groupUserAttributeCategoryAttributesDestroy",
          "groupUserAttributeCategoryIndex",
          "groupUserAttributeCategoryShow",
          "groupUserAttributeCategoryStore",
          "groupUserAttributeCategoryUpdate",
          "groupUserAttributeCategoryDestroy",
          "groupUserAttributeCategoryShowPublic",
          "groupUserAttributeStore",
          "groupUserAttributeSort",
          "groupUserAttributeUpdate",
          "groupUserAttributeDestroy",
          "groupUserAttributeDownloadSample",
          "groupUserAttributeImport",
          "groupUserAttributeRelationShow",
          "groupUserAttributeRelationStore",
          "groupUserAttributeRelationDestroy",
        ],
      },
    },
    {
      path: "/groups/:group_id/questions/:group_plugin_id/edit",
      name: "questions_edit",
      component: () => import("@/views/QuestionWizard.vue"),
      meta: {
        permissions: [],
        groupPolicies: [
          "groupPluginsStore",
          "groupPluginsUpdate",
          "groupPluginsDestroy",
          "groupMessageTemplatesIndex",
          "groupMessageTemplatesShow",
          "groupMessageTemplatesStore",
          "groupMessageTemplatesUpdate",
          "groupMessageTemplatesDestroy",
          "groupMessageTemplatesSendTest",
          "groupUserAttributeCategoryAttributesIndex",
          "groupUserAttributeCategoryAttributesShow",
          "groupUserAttributeCategoryAttributesStore",
          "groupUserAttributeCategoryAttributesUpdate",
          "groupUserAttributeCategoryAttributesDestroy",
          "groupUserAttributeCategoryIndex",
          "groupUserAttributeCategoryShow",
          "groupUserAttributeCategoryStore",
          "groupUserAttributeCategoryUpdate",
          "groupUserAttributeCategoryDestroy",
          "groupUserAttributeCategoryShowPublic",
          "groupUserAttributeStore",
          "groupUserAttributeSort",
          "groupUserAttributeUpdate",
          "groupUserAttributeDestroy",
          "groupUserAttributeDownloadSample",
          "groupUserAttributeImport",
          "groupUserAttributeRelationShow",
          "groupUserAttributeRelationStore",
          "groupUserAttributeRelationDestroy",
        ],
      },
    },
    {
      path: "/groups/:group_id/reports",
      name: "reports",
      component: () => import("@/views/ReportListPage.vue"),
      meta: {
        permissions: [],
        groupPolicies: [
          "groupReportsParamsIndex",
          "groupReportsParamsShow",
          "groupReportsParamsStore",
          "groupReportsParamsUpdate",
          "groupReportsParamsDestroy",
          "groupReportsIndex",
          "groupReportsShow",
          "groupReportsStore",
          "groupReportsUpdate",
          "groupReportsDestroy",
          "groupReportsExport",
        ],
      },
    },
    {
      path: "/groups/:group_id/reports/:report_id/view",
      name: "reports_view",
      component: () => import("@/views/ReportViewPage.vue"),
      meta: {
        permissions: [],
        groupPolicies: [
          "groupReportsParamsIndex",
          "groupReportsIndex",
          "groupReportsShow",
          "groupReportsExport",
        ],
      },
    },
    {
      path: "/groups/:group_id/reports/create",
      name: "reports_create",
      component: () => import("@/views/ReportPage.vue"),
      meta: {
        permissions: [],
        groupPolicies: [
          "groupReportsParamsIndex",
          "groupReportsParamsShow",
          "groupReportsParamsStore",
          "groupReportsParamsUpdate",
          "groupReportsParamsDestroy",
          "groupReportsIndex",
          "groupReportsShow",
          "groupReportsStore",
          "groupReportsUpdate",
          "groupReportsDestroy",
          "groupReportsExport",
        ],
      },
    },
    {
      path: "/groups/:group_id/reports/:report_id/edit",
      name: "reports_edit",
      component: () => import("@/views/ReportPage.vue"),
      meta: {
        permissions: [],
        groupPolicies: [
          "groupReportsParamsIndex",
          "groupReportsParamsShow",
          "groupReportsParamsStore",
          "groupReportsParamsUpdate",
          "groupReportsParamsDestroy",
          "groupReportsIndex",
          "groupReportsShow",
          "groupReportsStore",
          "groupReportsUpdate",
          "groupReportsDestroy",
          "groupReportsExport",
        ],
      },
    },
    {
      path: "/groups/:group_id/messages/:group_plugin_id",
      name: "group_message_templates",
      component: () => import("@/views/GroupMessageTemplateListPage.vue"),
      meta: {
        permissions: [],
        groupPolicies: [
          "groupMessageTemplatesIndex",
          "groupMessageTemplatesShow",
          "groupMessageTemplatesStore",
          "groupMessageTemplatesUpdate",
          "groupMessageTemplatesDestroy",
          "groupMessageTemplatesSendTest",
          "groupPluginsStore",
          "groupPluginsUpdate",
          "groupPluginsDestroy",
        ],
      },
    },
    {
      path: "/groups/:group_id/messages/:group_plugin_id/create",
      name: "group_message_templates_create",
      component: () => import("@/views/GroupMessageTemplateWizard.vue"),
      meta: {
        permissions: [],
        groupPolicies: [
          "groupMessageTemplatesIndex",
          "groupMessageTemplatesShow",
          "groupMessageTemplatesStore",
          "groupMessageTemplatesUpdate",
          "groupMessageTemplatesDestroy",
          "groupMessageTemplatesSendTest",
          "groupPluginsStore",
          "groupPluginsUpdate",
          "groupPluginsDestroy",
        ],
      },
    },
    {
      path: "/groups/:group_id/messages/:group_plugin_id/:message_id/edit",
      name: "group_message_templates_edit",
      component: () => import("@/views/GroupMessageTemplateWizard.vue"),
      meta: {
        permissions: [],
        groupPolicies: [
          "groupMessageTemplatesIndex",
          "groupMessageTemplatesShow",
          "groupMessageTemplatesStore",
          "groupMessageTemplatesUpdate",
          "groupMessageTemplatesDestroy",
          "groupMessageTemplatesSendTest",
          "groupPluginsStore",
          "groupPluginsUpdate",
          "groupPluginsDestroy",
        ],
      },
    },
    {
      path: "/groups/:group_id/message-delivery-status",
      name: "group_generated_messages",
      component: () => import("@/views/GroupMessageDeliveryStatus.vue"),
      meta: {
        permissions: [],
        groupPolicies: ["groupMessageTemplatesIndex"],
      },
    },
    {
      path: "/groups/:group_id/app-pages",
      name: "app_page_list",
      component: () => import("@/views/AppPageList.vue"),
      meta: {
        permissions: [],
        groupPolicies: [
          "groupMenuDestinationsStore",
          "groupMenuDestinationsUpdate",
          "groupMenuDestinationsDestroy",
          "groupMenuDestinationsReorder",
          "groupMenusStore",
          "groupMenusUpdate",
          "groupArticleStore",
          "groupArticleUpdate",
          "groupArticleDestroy",
        ],
      },
    },
    {
      path: "/groups/:group_id/app-pages/:menu_id/edit",
      name: "app_page_edit",
      component: () => import("@/views/AppPage.vue"),
      meta: {
        permissions: [],
        groupPolicies: [
          "groupMenuDestinationsStore",
          "groupMenuDestinationsUpdate",
          "groupMenuDestinationsDestroy",
          "groupMenuDestinationsReorder",
          "groupMenusStore",
          "groupMenusUpdate",
          "groupArticleStore",
          "groupArticleUpdate",
          "groupArticleDestroy",
        ],
      },
    },
    {
      path: "/groups/:group_id/app-pages/:menu_id/page/create",
      name: "app_page_wizard_create",
      component: () => import("@/views/AppPageWizard.vue"),
      meta: {
        permissions: [],
        groupPolicies: [
          "groupMenuDestinationsStore",
          "groupMenuDestinationsUpdate",
          "groupMenuDestinationsDestroy",
          "groupMenuDestinationsReorder",
          "groupMenusStore",
          "groupMenusUpdate",
          "groupArticleStore",
          "groupArticleUpdate",
          "groupArticleDestroy",
        ],
      },
    },
    {
      path: "/groups/:group_id/app-pages/:menu_id/page/:article_id/edit",
      name: "app_page_wizard_edit",
      component: () => import("@/views/AppPageWizard.vue"),
      meta: {
        permissions: [],
        groupPolicies: [
          "groupMenuDestinationsStore",
          "groupMenuDestinationsUpdate",
          "groupMenuDestinationsDestroy",
          "groupMenuDestinationsReorder",
          "groupMenusStore",
          "groupMenusUpdate",
          "groupArticleStore",
          "groupArticleUpdate",
          "groupArticleDestroy",
        ],
      },
    },
    {
      path: "/groups/:group_id/articles/:group_plugin_id",
      name: "articles",
      component: () => import("@/views/ArticleListPage.vue"),
      meta: {
        permissions: [],
        groupPolicies: [
          "groupArticleStore",
          "groupArticleUpdate",
          "groupArticleDestroy",
        ],
      },
    },
    {
      path: "/groups/:group_id/articles/:group_plugin_id/create",
      name: "articles_create",
      component: () => import("@/views/ArticleWizard.vue"),
      meta: {
        permissions: [],
        groupPolicies: [
          "groupArticleStore",
          "groupArticleUpdate",
          "groupArticleDestroy",
        ],
      },
    },
    {
      path: "/groups/:group_id/articles/:group_plugin_id/:article_id/edit",
      name: "articles_edit",
      component: () => import("@/views/ArticleWizard.vue"),
      meta: {
        permissions: [],
        groupPolicies: [
          "groupArticleStore",
          "groupArticleUpdate",
          "groupArticleDestroy",
        ],
      },
    },
    {
      path: "/groups/:group_id/administrators",
      name: "group_administrators",
      component: () => import("@/views/AdministratorListPage.vue"),
      meta: {
        permissions: [],
        groupPolicies: [
          "groupAdministratorsIndex",
          "groupAdministratorsStore",
          "groupAdministratorsDestroy",
          "groupAdministratorsShow",
          "groupAdministratorsRoles",
        ],
      },
    },
    {
      path: "/routes",
      name: "routes",
      component: () => import("@/views/RouteList.vue"),
      meta: {
        permissions: ["admin_permissions"],
        groupPolicies: [],
      },
    },
    {
      path: "/401",
      name: "401",
      component: () => import("@/views/Unauthenticated.vue"),
    },
    {
      path: "*",
      name: "404",
      component: () => import("@/views/NotFound.vue"),
    },
  ],
});

router.beforeEach(async (to, from, next) => {
  const permissions = to.meta.permissions;
  const groupPolicies = to.meta.groupPolicies;
  const currentUser = store.getters.currentUser;

  if (permissions || groupPolicies) {
    if (!currentUser || !currentUser.id) {
      return next({ name: "authorize" });
    }
  }

  next();
});

export default router;
