import Vue from "vue";
import Vuex from "vuex";

import createPersistedState from "vuex-persistedstate";
import loading from "./loading";
import notifications from "./_notifications";
import settings from "./settings";
import menu from "./menu";
import auth from "./auth";
import groups from "./groups";
import deposits from "./deposits";
import depositTypes from "./depositTypes";
import depositories from "./depositories";
import languages from "./languages";
import depositsHistory from "./depositsHistory";
import questionWizard from "./questionWizard";
import groupWizard from "./groupWizard";
import signupWizard from "./signupWizard";
import appPageWizard from "./appPageWizard";
import articleWizard from "./articleWizard";
import groupPage from "./groupPage";
import groupMessageTemplateWizard from "./groupMessageTemplateWizard";
import ticketTemplateWizard from "./ticketTemplateWizard";
import groupDataLocalStorage from "./groupDataLocalStorage";
import groupUserAttributeWizard from "./groupUserAttributeWizard";
import groupUserPage from "./groupUserPage";
import audiencePage from "./audiencePage";

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    loading,
    notifications,
    settings,
    menu,
    auth,
    groups,
    deposits,
    depositTypes,
    depositories,
    languages,
    depositsHistory,
    questionWizard,
    groupWizard,
    signupWizard,
    appPageWizard,
    articleWizard,
    groupPage,
    groupMessageTemplateWizard,
    ticketTemplateWizard,
    groupDataLocalStorage,
    groupUserAttributeWizard,
    groupUserPage,
    audiencePage,
  },
  state: {
    //
  },
  getters: {
    // Store in modules
  },
  mutations: {
    // Store in modules
  },
  actions: {
    // Store in modules
  },
  plugins: [
    createPersistedState({
      key: "superAppEngine",
      // storage: window.localStorage,
      paths: [
        "auth.user",
        "auth.token",
        "menu.showLeftMenu",
        "languages",
        "depositsHistory",
        "groupDataLocalStorage",
      ],
    }),
  ],
});

export default store;
