const state = {
  groupWizardStepper: 0,
  groupWizardGroup: {},
  groupWizardGroupPaymentsEnabled: false,
  groupWizardGroupCurrency: "",
  groupWizardAdministrators: [],
};

const getters = {
  groupWizardStepper: (state) => state.groupWizardStepper,
  groupWizardGroup: (state) => state.groupWizardGroup,
  groupWizardGroupPaymentsEnabled: (state) => state.groupWizardGroupPaymentsEnabled,
  groupWizardGroupCurrency: (state) => state.groupWizardGroupCurrency,
  groupWizardAdministrators: (state) => state.groupWizardAdministrators,
};

const mutations = {
  SET_GROUP_WIZARD_STEPPER: (state, params) => {
    state.groupWizardStepper = params;
  },
  SET_GROUP_WIZARD_GROUP: (state, params) => {
    if (!params.email_sender_address) {
      params.email_sender_address = process.env.VUE_APP_EMAIL_SENDER_ADDRESS;
    }
    state.groupWizardGroup = params;
  },
  SET_GROUP_WIZARD_GROUP_PAYMENTS_ENABLED: (state, params) => {
    state.groupWizardGroupPaymentsEnabled = params;
  },
  SET_GROUP_WIZARD_GROUP_CURRENCY: (state, params) => {
    state.groupWizardGroupCurrency = params;
  },
  SET_GROUP_WIZARD_ADMINISTRATORS: (state, params) => {
    state.groupWizardAdministrators = params;
  },
};

const actions = {
  setGroupWizardStepper: async ({ commit }, params) => {
    commit("SET_GROUP_WIZARD_STEPPER", params);
  },
  setGroupWizardGroup: async ({ commit }, params) => {
    commit("SET_GROUP_WIZARD_GROUP", params);
  },
  setGroupWizardGroupPaymentsEnabled: async ({ commit }, params) => {
    commit("SET_GROUP_WIZARD_GROUP_PAYMENTS_ENABLED", params);
  },
  setGroupWizardGroupCurrency: async ({ commit }, params) => {
    commit("SET_GROUP_WIZARD_GROUP_CURRENCY", params);
  },
  setGroupWizardAdministrators: async ({ commit }, params) => {
    commit("SET_GROUP_WIZARD_ADMINISTRATORS", params);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
