const state = {
  userPageProperties: [],
  userPageAttributes: [],
  userPageAttributeValues: {},
  completeAttributeCategory: false,
};

const getters = {
  userPageProperties: (state) => state.userPageProperties,
  userPageAttributes: (state) => state.userPageAttributes,
  userPageAttributeValues: (state) => state.userPageAttributeValues,
  completeAttributeCategory: (state) => state.completeAttributeCategory,
};

const mutations = {
  SET_USER_PAGE_PROPERTIES: (state, data) => {
    state.userPageProperties = data;
  },
  SET_USER_PAGE_ATTRIBUTES: (state, data) => {
    state.userPageAttributes = data;
  },
  SET_USER_PAGE_ATTRIBUTE_VALUES: (state, data) => {
    state.userPageAttributeValues = data;
  },
  SET_COMPLETE_ATTRIBUTE_CATEGORY: (state, data) => {
    state.completeAttributeCategory = data;
  },
};

const actions = {
  setUserPageProperties: async ({ commit }, data) => {
    commit("SET_USER_PAGE_PROPERTIES", data);
  },
  setUserPageAttributes: async ({ commit }, data) => {
    commit("SET_USER_PAGE_ATTRIBUTES", data);
  },
  setUserPageAttributeValues: async ({ commit }, data) => {
    commit("SET_USER_PAGE_ATTRIBUTE_VALUES", data);
  },
  setCompleteAttributeCategory: async ({ commit }, data) => {
    commit("SET_COMPLETE_ATTRIBUTE_CATEGORY", data);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
