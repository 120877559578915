<template>
  <div>
    <div v-if="showDrawer" :class="['navigation-drawer', { mini: !drawer }]">
      <div v-if="isLoading" class="preloader d-flex align-center">
        <v-progress-circular
          indeterminate
          class="sw-accent"
        ></v-progress-circular>
      </div>
      <overlay-scrollbars
        v-if="!isLoading"
        :options="scrollbarOptions"
        :style="{ maxHeight: '100%' }"
      >
        <div class="navigation-items">
          <template v-if="!groupId">
            <div v-for="(item, i) in superadminMenu()" :key="`item-${i}`">
              <router-link :to="item.route">
                <div class="menu-item">
                  <div class="item-icon">
                    <v-avatar>
                      <font-awesome-icon :icon="transformIcon(item.icon)" />
                    </v-avatar>
                  </div>
                  <transition
                    v-on:before-enter="beforeEnter"
                    v-on:after-enter="afterEnter"
                    v-on:before-leave="beforeLeave"
                  >
                    <div v-show="drawer" class="item-text">
                      {{ item.title }}
                    </div>
                  </transition>
                </div>
              </router-link>
            </div>
          </template>
          <template v-if="groupId">
            <div v-for="(item, i) in groupMenu()" :key="`item-${i}`">
              <router-link :to="item.route">
                <div class="menu-item">
                  <div class="item-icon">
                    <v-avatar>
                      <font-awesome-icon :icon="transformIcon(item.icon)" />
                    </v-avatar>
                  </div>
                  <transition
                    v-on:before-enter="beforeEnter"
                    v-on:after-enter="afterEnter"
                    v-on:before-leave="beforeLeave"
                  >
                    <div v-show="drawer" class="item-text">{{ item.name }}</div>
                  </transition>
                </div>
              </router-link>
            </div>
          </template>
        </div>
      </overlay-scrollbars>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { groupMenuMixin } from "@/mixins/groupMenuMixin";

export default {
  mixins: [groupMenuMixin],
  data: () => ({
    isLoading: false,
    scrollbarOptions: {
      scrollbars: { autoHide: "leave" },
      overflowBehavior: {
        x: "scroll",
      },
      sizeAutoCapable: true,
    },
  }),
  computed: {
    ...mapGetters([
      "currentUser",
      "currentUserPermissions",
      "userRolesInGroup",
      "appLanguage",
      "getShowLeftMenu",
    ]),
    groupId() {
      return this.$route.params.group_id;
    },
    drawer: {
      get() {
        return this.getShowLeftMenu;
      },
      set(val) {
        this.$store.dispatch("setShowLeftMenu", val);
      },
    },
    showDrawer() {
      if (!this.groupId) {
        return this.superadminMenu().length > 0;
      }
      return this.groupMenu().length > 0;
    },
  },
  methods: {
    getGroupMenu() {
      if (!this.groupId) return;

      this.isLoading = true;

      const params = [
        this.groupId,
        {
          lang: this.appLanguage,
        },
      ];

      this.$store.dispatch("getGroupLeftMenu", params).then(
        () => (this.isLoading = false),
        () => (this.isLoading = false),
      );
    },
    superadminMenu() {
      return [
        {
          title: this.$t("menu-item-app-status"),
          icon: "fas clipboard-list",
          route: { name: "status" },
          enabled: true,
        },
        {
          title: this.$t("menu-item-migrations"),
          icon: "fas puzzle-piece",
          route: { name: "migrations" },
          enabled: true,
        },
        {
          title: this.$t("menu-item-users"),
          icon: "fas users",
          route: { name: "users" },
          enabled: true,
        },
        {
          title: "Roles",
          icon: "fas user-tie",
          route: { name: "roles" },
          enabled: true,
        },
        {
          title: "Group roles",
          icon: "fas user-tie",
          route: { name: "group_roles" },
          enabled: true,
        },
        {
          title: "Group policies",
          icon: "fas key",
          route: { name: "group_policies" },
          enabled: true,
        },
        {
          title: this.$t("menu-item-messages"),
          icon: "fas envelope-open",
          route: { name: "message_templates" },
          enabled: true,
        },
        {
          title: this.$t("menu-item-settings"),
          icon: "fas question",
          route: { name: "settings" },
          enabled: true,
        },
        {
          title: this.$t("admin_menu.printout_templates"),
          icon: "fas image",
          route: { name: "printout_templates" },
          enabled: true,
        },
        {
          title: "Venues",
          icon: "fas info",
          route: { name: "venues" },
          enabled: true,
        },
        {
          title: "Routes",
          icon: "fas clipboard-list",
          route: { name: "routes" },
          enabled: true,
        },
      ].filter((el) => el.enabled && this.hasAccess(el.route.name));
    },
    transformIcon(icon) {
      if (!icon) return "question";
      return icon.split(" ");
    },
    beforeEnter(el) {
      el.style.transitionDelay = "0.15s";
      el.style.transition = "0.25s";
      el.style.opacity = 0;
    },
    afterEnter(el) {
      el.style.opacity = 1;
    },
    beforeLeave(el) {
      el.style.transitionDelay = "none";
      el.style.transition = "none";
      el.style.opacity = 0;
    },
  },
  watch: {
    groupId: {
      immediate: true,
      handler() {
        this.listGroupPolicies();
        this.getGroupMenu();
      },
    },
    appLanguage: {
      handler() {
        this.getGroupMenu();
      },
    },
    showDrawer: {
      immediate: true,
      handler(val) {
        this.$store.commit("SET_NAVIGATION_DRAWER_VISIBILITY", val);
      },
    },
  },
};
</script>

<style lang="scss" scopoed>
.navigation-drawer {
  background-color: $primary-bg;
  position: fixed;
  height: calc(100% - 135px);
  // transform: translateX(0px);
  width: 320px;
  max-height: calc(100% - 135px);
  display: block;
  left: 0;
  max-width: 100%;
  pointer-events: auto;
  top: 135px;
  will-change: transform;
  z-index: 3;
  transition: 0.25s;

  .preloader {
    height: calc(100%);
  }

  .navigation-items {
    padding: 90px 0 0 57px;

    .menu-item {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      margin-bottom: 30px;
      cursor: pointer;

      .item-icon {
        .v-avatar {
          width: 63px !important;
          height: 63px !important;
          font-size: 30px;
          color: $on-primary;
          transition: 0.25s;
        }
      }

      .item-text {
        padding: 0 0 0 40px;
        font-size: 16px;
        font-weight: 300;
        color: $on-primary;
      }
    }

    .router-link-active {
      .item-icon {
        .v-avatar {
          background: $accent;
          color: $on-accent;
        }
      }

      .item-text {
        font-weight: 600;
        color: $accent;
      }
    }
  }

  &.mini {
    width: 180px;
  }

  .os-scrollbar-handle {
    background: darken($primary-bg, 12%) !important;
  }
}
</style>
