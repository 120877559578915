<template>
  <div id="icon-selector">
    <v-layout row class="align-center">
      <span
        @click="showIconDialog"
        class="selected-icon sw-accent-bg d-flex align-center"
      >
        <font-awesome-icon
          v-if="model"
          :icon="transformIcon(model)"
          color="white"
        />
      </span>
      <a
        v-if="label"
        class="pl-3 caption grey--text hover"
        @click="showIconDialog"
      >
        {{ label }}
      </a>
    </v-layout>
    <v-dialog v-model="iconDialog" width="600">
      <v-card class="rounded">
        <v-layout class="row wrap">
          <v-flex class="xs12 text-xs-right">
            <v-btn icon @click="iconDialog = false">
              <v-icon small>close</v-icon>
            </v-btn>
          </v-flex>
          <v-flex class="xs12 px-4">
            <v-text-field
              v-model="searchInput"
              @keyup.enter.native="selectIcon()"
              autofocus
              hide-details
              :label="$t('iconSelectorLabel')"
            ></v-text-field>
          </v-flex>
          <v-flex class="xs12 py-4">
            <overlay-scrollbars :options="scrollbarOptions">
              <div
                v-if="filtredIcons.length === 0"
                class="px-4 text-center"
                :style="{ maxHeight: '265px' }"
              >
                {{ $t("noIconsFound") }}
              </div>
              <v-layout
                v-if="filtredIcons"
                class="row wrap"
                :style="{ maxHeight: '265px' }"
              >
                <v-flex
                  v-for="(icon, i) in filtredIcons"
                  :key="'icon-' + i"
                  @click="selectIcon(icon)"
                  class="xs2 py-3 text-center cursor-pointer icon-element"
                >
                  <font-awesome-icon
                    :icon="transformIcon(icon)"
                    class="sw-primary-light"
                    :style="{ fontSize: '16px' }"
                  />
                </v-flex>
              </v-layout>
            </overlay-scrollbars>
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "IconSelector",
  props: {
    value: {
      type: String,
    },
    defaultIcon: {
      type: String,
      default: null,
    },
    label: {
      type: String,
    },
  },
  data: () => ({
    iconDialog: false,
    scrollbarOptions: {
      scrollbars: { autoHide: "leave" },
      overflowBehavior: {
        x: "scroll",
      },
      sizeAutoCapable: true,
    },
    searchInput: "",
    icons: [
      "fas question-circle",
      "fas question",
      "fas users",
      "fas user",
      "fas user-circle",
      "fas id-card-alt",
      "fas id-card",
      "fas address-card",
      "fas id-badge",
      "fas bed",
      "fas couch",
      "fas hotel",
      "fas home",
      "fas newspaper",
      "fas file-alt",
      "fas info",
      "fas info-circle",
      "fas barcode",
      "fas sticky-note",
      "fas envelope-open-text",
      "fas envelope-open",
      "fas comments",
      "fas comment",
      "fas exclamation",
      "fas exclamation-circle",
      "fas exclamation-triangle",
      "fas tasks",
      "fas list-alt",
      "fas bus-alt",
      "fas car",
      "fas taxi",
      "fas clipboard",
      "fas clipboard-list",
      "fas clipboard-check",
      "fas clock",
      "fas directions",
      "fas location-arrow",
      "fas atlas",
      "fas ticket-alt",
      "fas tshirt",
      "fas tag",
      "fas shopping-bag",
      "fas briefcase",
      "fas coffee",
      "fas anchor",
      "fas apple-alt",
      "fas asterisk",
      "fas atom",
      "fas ban",
      "fas bell",
      "fas cat",
      "fas cloud",
      "fas code",
      "fas dog",
      "fas eye",
      "fas file-download",
      "fas flask",
      "fas futbol",
      "fas palette",
      "fas pen",
      "fas sun",
      "fas trash",
      "fas envelope",
      "fas sms",
      "fas at",
      "fas glass-cheers",
      "fas user-tie",
      "fas address-book",
      "fas chart-pie",
      "fas cog",
      "fas user-cog",
      "fas hammer",
      "fas puzzle-piece",
      "fas wrench",
      "fas image",
      "fas book-open",
      "fas map",
      "fas file-code",
      "fas file-csv",
      "fas file-excel",
      "fas file-pdf",
      "fas wine-glass-alt",
      "fas bullhorn",
      "fas bus",
      "fas phone-square",
      "fas check-circle",
      "fas running",
      "fas map-marker-alt",
      "fas print",
      "fas rocket",
      "fas chart-area",
      "fas wallet",
    ],
  }),
  computed: {
    model: {
      get() {
        return this.value ? this.value : this.defaultIcon;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    availableIcons() {
      return this.icons;
    },
    filtredIcons() {
      return this.availableIcons.filter((icon) =>
        icon.toUpperCase().includes(this.searchInput.toUpperCase()),
      );
    },
  },
  methods: {
    transformIcon(icon) {
      if (icon) {
        return icon.split(" ");
      }
    },
    showIconDialog() {
      this.searchInput = "";
      this.iconDialog = true;
    },
    selectIcon(icon) {
      if (icon) {
        this.model = icon;
        this.iconDialog = false;
      } else if (this.filtredIcons.length === 1) {
        this.model = this.filtredIcons[0];
        this.iconDialog = false;
      }
      this.$emit("change", icon);
    },
  },
};
</script>

<style scoped lang="scss">
#icon-selector {
  .selected-icon {
    width: 40px;
    height: 40px;
    text-align: center;
    border-radius: 20px;
    font-size: 14px;
    cursor: pointer;
  }
}

.icon-element:hover svg {
  -webkit-transition: 0.3s;
  /* Safari */
  transition: 0.3s;
  color: $primary !important;
}
</style>
