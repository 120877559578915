<template>
  <div v-if="globalDateFormat" class="date-picker-wrapper">
    <div v-if="label">
      <div class="label">{{ label }}</div>
    </div>
    <date-picker
      style="width: 100%"
      v-model="datepicker"
      type="date"
      :lang="datePickerLang"
      :first-day-of-week="1"
      :format="globalDateFormat"
      :placeholder="$t('common.date_picker_placeholder')"
      :editable="false"
      :disabled="disabled"
    ></date-picker>
  </div>
</template>

<script>
import moment from "moment-timezone";
import DatePicker from "vue2-datepicker";

export default {
  props: {
    value: {
      type: String,
    },
    label: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    globalDateFormat() {
      return this.$store.getters.globalDateFormat;
    },
    datepicker: {
      get() {
        return this.value
          ? moment(this.value).format("YYYY-MM-DDTHH:mm:ssZ")
          : null;
      },
      set(val) {
        this.$emit(
          "input",
          val ? moment(val).format("YYYY-MM-DDTHH:mm:ssZ") : null,
        );
      },
    },
  },
  components: {
    DatePicker,
  },
};
</script>

<style scoped lang="scss">
.date-picker-wrapper {
  padding-top: 2px;

  .label {
    font-size: 12px;
    color: #c9c9c9;
    line-height: 20px;
    height: 20px;
  }
}
</style>
