<template>
  <div class="toolbar align-center">
    <div
      class="toolbar-left align-center"
      :class="{ mini: !drawer }"
    >
      <div
        class="branding d-flex align-center"
        :class="{ drawer: isDrawerVisible, mini: !drawer }"
        @click="toGroupList"
      >
        <div class="app-logo">
          <v-img :src="require(`@/assets/images/logo.png`)"></v-img>
        </div>
        <template v-if="isAppNameVisible">
          <transition
            v-on:before-enter="beforeEnter"
            v-on:after-enter="afterEnter"
            v-on:before-leave="beforeLeave"
          >
            <div
              v-if="drawer || !isDrawerVisible"
              class="app-name"
            >
              {{ appName }}
            </div>
          </transition>
        </template>
      </div>
      <div
        v-if="isDrawerVisible"
        class="toggler d-flex align-center"
      >
        <div class="text-xs-center">
          <v-btn
            fab
            flat
            icon
            class="toggler-btn"
            @click="toggleLeftMenu"
          >
            <font-awesome-icon
              :icon="!drawer ? 'grip-lines-vertical' : 'grip-lines'"
              size="2x"
            />
          </v-btn>
        </div>
      </div>
    </div>
    <div class="sw-on-primary mr-auto event-name">
      <span v-show="groupName ? true : false">{{ groupName }}</span>
    </div>
    <div class="user-menu text-center">
      <v-menu
        v-if="user.id"
        v-model="userMenu"
        :close-on-content-click="false"
        :nudge-width="200"
        offset-x
        nudge-left
      >
        <v-badge
          overlap
          slot="activator"
        >
          <v-avatar
            size="64"
            class="mb-3 sw-secondary-bg"
          >
            <span class="sw-on-secondary font-weight-medium">{{
              avatarText
            }}</span>
          </v-avatar>
          <div class="user-name sw-on-primary font-weight-light">
            {{ user.first_name }} {{ user.last_name }}
          </div>
        </v-badge>
        <v-card
          width="400"
          flat
          class="sw-pa-2"
        >
          <v-list class="sw-mb-2 sw-pa-0">
            <v-list-tile avatar>
              <v-list-tile-avatar>
                <v-avatar
                  class="tile"
                  size="40"
                  color="sw-secondary-bg"
                  :style="{ fontSize: '14px' }"
                >
                  <span class="sw-on-secondary">{{ avatarText }}</span>
                </v-avatar>
              </v-list-tile-avatar>
              <v-list-tile-content>
                <v-list-tile-title class="sw-primary">
                  {{ user.first_name }}
                  {{ user.last_name }}
                </v-list-tile-title>
              </v-list-tile-content>
              <v-list-tile-action>
                <v-btn
                  icon
                  class="ma-0 sw-secondary"
                  :loading="isLoading"
                  @click="logout"
                >
                  <v-icon small>logout</v-icon>
                </v-btn>
              </v-list-tile-action>
            </v-list-tile>
          </v-list>
          <div class="sw-px-1">
            <v-layout
              v-if="!hasLimitedAcces"
              row
              wrap
            >
              <v-flex xs12>
                <span
                  class="sw-h5 font-weight-light sw-primary cursor-pointer"
                  @click="toProfile"
                  >Profile</span
                >
              </v-flex>
              <v-flex
                xs12
                class="py-2"
                ><v-divider></v-divider
              ></v-flex>
            </v-layout>
            <div class="sw-mb-1">
              <AppLanguageSwitcher @input="closeUserMenu()" />
            </div>
          </div>
        </v-card>
      </v-menu>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import AppLanguageSwitcher from "@/components/AppLanguageSwitcher.vue";

export default {
  data: () => ({ isLoading: false, userMenu: false }),
  computed: {
    ...mapGetters(["currentUser", "getShowLeftMenu"]),
    groupId() {
      return this.$route.params.group_id;
    },
    appName() {
      return process.env.VUE_APP_NAME;
    },
    isAppNameVisible() {
      return process.env.VUE_APP_IS_NAME_VISIBLE === "true" ? true : false;
    },
    drawer: {
      get() {
        return this.getShowLeftMenu;
      },
      set(val) {
        this.$store.dispatch("setShowLeftMenu", val);
      },
    },
    isDrawerVisible() {
      return this.$store.getters.isNavigationDrawerVisible;
    },
    groupName() {
      return this.$store.getters.activeGroup.name;
    },
    hasLimitedAcces() {
      const roles = [
        "group_ticket_assistant",
        "group_ticket_manager",
        "group_depository_assistant",
        "group_event_supervisor",
      ];
      return this.user
        ? this.user.roles.some((role) => roles.indexOf(role) !== -1)
        : false;
    },
    user() {
      return this.currentUser || {};
    },
    avatarText() {
      if (!this.user.first_name || !this.user.last_name) {
        return "";
      }

      return (
        this.user.first_name.substring(0, 1) +
        this.user.last_name.substring(0, 1)
      );
    },
  },
  components: {
    AppLanguageSwitcher,
  },
  methods: {
    toProfile() {
      this.$router.push(
        { name: "profile", query: { group_id: this.groupId } },
        () => {},
      );

      this.userMenu = false;
    },
    toGroupList() {
      this.$router.push({ name: "home" }).catch(() => {});
    },
    clearSession() {
      this.$store.commit("SET_USER", {});
      this.$store.commit("SET_TOKEN", "");

      this.userMenu = false;

      this.$router
        .push({
          name: "authorize",
        })
        .catch(() => {});
    },
    logout() {
      this.isLoading = true;

      this.$api.auth
        .logout()
        .then((response) => {
          this.isLoading = false;
          this.clearSession();
        })
        .catch(() => {
          this.isLoading = false;
          this.clearSession();
        });
    },
    toggleLeftMenu() {
      this.drawer = !this.drawer;
    },
    beforeEnter(el) {
      el.style.transitionDelay = "0.15s";
      el.style.transition = "0.25s";
      el.style.opacity = 0;
    },
    afterEnter(el) {
      el.style.opacity = 1;
    },
    beforeLeave(el) {
      el.style.transitionDelay = "none";
      el.style.transition = "none";
      el.style.opacity = 0;
    },
    closeUserMenu() {
      this.userMenu = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.toolbar {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
  width: 100%;
  height: 135px;
  background-color: $primary-bg !important;
  display: flex;

  .toolbar-left {
    display: flex;
    width: 455px;
    transition: all 0.25s;

    &.mini {
      width: 315px;
    }

    .branding {
      width: 320px;
      height: 135px;
      padding: 0 0 0 57px;
      background-color: $primary-bg !important;
      transition: 0.25s;
      cursor: pointer;

      &.drawer {
        width: 320px;

        &.mini {
          width: 180px;
        }
      }

      .app-logo {
        max-width: 63px;
        min-width: 63px;
        padding: 0 1px;
      }

      .app-name {
        padding: 0 0 0 40px;
        font-size: 1.6rem;
        font-weight: 900;
        color: $on-primary;
      }
    }

    .toggler {
      width: 135px;
      height: 135px;

      .toggler-btn {
        color: $primary;
      }
    }
  }

  .event-name {
    display: flex;
    align-items: center;
    margin-left: 35px;
    font-size: 2.1rem;
    font-weight: 500;
  }

  .user-menu {
    padding: 0 40px 0 0;

    .user-name {
      font-size: 16px;
      line-height: 16px;
    }
  }
}
</style>
